/**
 * Parses the given HTML string and replaces all variables with the given substitutions.
 *
 * Variables are defined as <span data-var="variableName">content</span>.
 * A Variable will be replaced by the text from the substitutions object with the key variableName e.g. <span>substitutions[variableName]</span>.
 *
 *
 * @param html
 * @param substitutions
 * @param opts.asText Returns plaintext instead of HTML if true.
 */
export function renderTemplate(
    html: string,
    substitutions: Record<string, string>,
    opts?: { asText: boolean },
): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const varNodes = doc.querySelectorAll('[data-var]');
    varNodes.forEach((varNode) => {
        const varName = varNode.getAttribute('data-var');

        if (varName) {
            const content = substitutions[varName];

            if (isUrl(content)) {
                const elem = document.createElement('a');
                elem.href = content;
                elem.textContent = content;
                varNode.removeAttribute('data-var');
                varNode.removeAttribute('style');
                varNode.innerHTML = elem.outerHTML;
                return;
            } else {
                varNode.outerHTML = content;
                varNode.removeAttribute('data-var');
                varNode.removeAttribute('style');
            }
        }
    });
    console.log(doc.body.textContent || '');
    if (opts?.asText) {
        return doc.body.textContent || '';
    }
    return doc.body.innerHTML;
}

function isUrl(text: string): boolean {
    try {
        new URL(text);
        return true;
    } catch {
        return false;
    }
}
