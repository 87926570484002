import * as r from 'runtypes';

export type QuoteImporterProblem = r.Static<typeof QuoteImporterProblemsRuntype>;
const QuoteImporterProblemRuntype = r.Record({
    cell: r.String,
    line: r.Number,
});
export const QuoteImporterProblemsRuntype = r.Union(
    r.Record({
        StringExtractionProblem: QuoteImporterProblemRuntype,
    }),
    r.Record({
        FloatExtractionProblem: QuoteImporterProblemRuntype,
    }),
    r.Record({
        GeneralError: QuoteImporterProblemRuntype,
    }),
    r.Record({
        NegativeValueError: QuoteImporterProblemRuntype,
    }),
    r.Record({
        ZeroUnitPriceError: QuoteImporterProblemRuntype,
    }),
);

export const ErrorCodeRuntype = r.Union(
    r.Literal('additional_service.english_name_cannot_be_empty'),
    r.Literal('additional_service.german_name_cannot_be_empty'),
    r.Literal('assembly.bom_file_not_found'),
    r.Literal('assembly.cannot_add_top_level_assembly_as_customer_user'),
    r.Literal('assembly.not_found'),
    r.Literal('auto_rfq_number_settings.cant_increment_manual_rfq_number'),
    r.Literal('auto_rfq_number_settings.invalid_auto_rfq_number_range'),
    r.Literal('bom_export.cannot_delete_default_template'),
    r.Literal('bom_export.cannot_delete_last_template'),
    r.Literal('bom_export.cannot_update_default_status_of_default_template'),
    r.Literal('bom_export.should_contain_at_least_one_column'),
    r.Literal('bom_export.template_with_name_already_exists'),
    r.Literal('bom_import.cannot_read_file'),
    r.Literal('bom_import.duplicate_designators'),
    r.Literal('costed_bom_template.cannot_delete_default_template'),
    r.Literal('costed_bom_template.cannot_delete_last_template'),
    r.Literal('costed_bom_template.cannot_update_default_status_of_default_template'),
    r.Literal('costed_bom_template.should_contain_at_least_one_column'),
    r.Literal('costed_bom_template.template_with_name_already_exists'),
    r.Literal('customer_portal_settings.calculation_template_required'),
    r.Literal('customer_portal_settings.invalid_auto_rfq_number_range'),
    r.Literal('customer_portal_settings.manufacturing_template_required'),
    r.Literal('customer.billing_address_incomplete'),
    r.Literal('customer.number_already_exists'),
    r.Literal('customer.payload_vat_identification_number_missing'),
    r.Literal('customer.payload_vat_identification_type_invalid'),
    r.Literal('customer.vat_id_incomplete'),
    r.Literal('demand.delivery_batch_size_out_of_bounds'),
    r.Literal('demand.invalid_annual_demand'),
    r.Literal('demand.invalid_assembly_id'),
    r.Literal('demand.invalid_demand_scenario_end_year'),
    r.Literal('demand.invalid_demand_scenario_year'),
    r.Literal('demand.invalid_prototype_demand_quantity'),
    r.Literal('demand.missing_annual_demand'),
    r.Literal('demand.more_than_three_delivery_batch_sizes'),
    r.Literal('demand.multiple_annual_demands_for_one_year'),
    r.Literal('demand.no_annual_demands'),
    r.Literal('demand.prototype_demand_scenario_date_not_in_future'),
    r.Literal('design_item.belong_to_different_rfq'),
    r.Literal('design_item.not_found'),
    r.Literal('design_item.rfq_not_found'),
    r.Literal('driver.first_char_must_be_letter'),
    r.Literal('driver.invalid_lexorank'),
    r.Literal('driver.invalid_package_name'),
    r.Literal('driver.name_already_in_use'),
    r.Literal('driver.name_is_reserved_keyword'),
    r.Literal('driver.name_is_system_driver'),
    r.Literal('driver.name_must_be_alphanumeric'),
    r.Literal('driver.name_must_not_be_empty'),
    r.Literal('email_template.name.already_exists'),
    r.Literal('email_template.suppliers.conflict'),
    r.Literal('email.other_error'),
    r.Literal('email.payload_too_large'),
    r.Literal('frozen_scenario_cost.already_frozen'),
    r.Literal('integration.error'),
    r.Literal('integration.invalid_api_key'),
    r.Literal('integration.invalid_credentials'),
    r.Literal('integration.invalid_settings'),
    r.Literal('integration.rate_limited'),
    r.Literal('invite.email_already_exists'),
    r.Literal('invite.invalid_user_invite'),
    r.Literal('invite.invite_already_sent'),
    r.Literal('manufacturing_assembly_details.driver_does_not_use_sourcing_field'),
    r.Literal('manufacturing_assembly_details.panel_factor_less_than_one'),
    r.Literal('manufacturing_scenario_template.already_exists'),
    r.Literal('manufacturing_scenario.name_already_exists'),
    r.Literal('not_found'),
    r.Literal('off_the_shelf.duplicated_mpn'),
    r.Literal('off_the_shelf.mpn_length_violation'),
    r.Literal('off_the_shelf.package_violation'),
    r.Literal('off_the_shelf.part_already_exists'),
    r.Literal('order.not_found'),
    r.Literal('order.sourcing_offers_pending'),
    r.Literal('panel.generation.pcb_height_exceeds_panel'),
    r.Literal('panel.generation.pcb_width_exceeds_panel'),
    r.Literal('panel.generation.unknown_error'),
    r.Literal('payload.customer_company_name_cannot_be_empty'),
    r.Literal('payload.email_cannot_be_empty'),
    r.Literal('payload.first_name_cannot_be_empty'),
    r.Literal('payload.last_name_cannot_be_empty'),
    r.Literal('payload.password_cannot_contain_personal_data'),
    r.Literal('payload.password_mismatch'),
    r.Literal('payload.vat_identification_number_cannot_be_empty'),
    r.Literal('payload.weak_password'),
    r.Literal('payment.already_scheduled_for_refunded'),
    r.Literal('payment.amount_mismatch'),
    r.Literal('payment.invalid_currency'),
    r.Literal('payment.no_configured_payment_processor'),
    r.Literal('payment.not_found'),
    r.Literal('payment.refunded'),
    r.Literal('payment.scheduled_for_refunded'),
    r.Literal('pnp_file.not_found'),
    r.Literal('pnp_importer.column_missing'),
    r.Literal('pnp_importer.csv'),
    r.Literal('pnp_importer.invalid_mapping'),
    r.Literal('pnp_importer.invalid_rfq_state'),
    r.Literal('pnp_importer.multiple_mapping'),
    r.Literal('pnp_item.not_found'),
    r.Literal('purchase_order.conflict'),
    r.Literal('quote_importer.extraction'),
    r.Literal('quote_importer.general'),
    r.Literal('quote_importer.invalid_factory_lead_time'),
    r.Literal('quote_importer.invalid_lead_time'),
    r.Literal('quote_importer.invalid_moq'),
    r.Literal('quote_importer.invalid_mpq'),
    r.Literal('quote_importer.invalid_unit_of_measurement'),
    r.Literal('quote_importer.negative_factory_quantity'),
    r.Literal('quote_importer.negative_stock'),
    r.Literal('quote_importer.negative_unit_price'),
    r.Literal('quote_importer.no_supplier'),
    r.Literal('quote_importer.part_not_found'),
    r.Literal('quote_importer.suppliers_not_equal'),
    r.Literal('quote_importer.unsupported_part_type'),
    r.Literal('rfq.cannot_create_customer_as_customer_user'),
    r.Literal('rfq.cannot_create_rfq_for_different_customer'),
    r.Literal('rfq.cannot_update_archived'),
    r.Literal('rfq.customer_does_not_exist'),
    r.Literal('rfq.customer_user_has_no_customer'),
    r.Literal('rfq.delete_rfq_with_sales_order_prohibited'),
    r.Literal('rfq.duplicate_assembly_names'),
    r.Literal('rfq.duplicate_order_sizes'),
    r.Literal('rfq.invalid_assembly_name'),
    r.Literal('rfq.invalid_customer_name'),
    r.Literal('rfq.invalid_name'),
    r.Literal('rfq.invalid_order_date'),
    r.Literal('rfq.invalid_order_size'),
    r.Literal('rfq.invalid_rfq_number'),
    r.Literal('rfq.invalid_volume_estimate_bounds'),
    r.Literal('rfq.new_customer_name_unavailable'),
    r.Literal('rfq.not_found'),
    r.Literal('rfq.request_in_draft_only_allowed_if_customer_portal_is_enabled'),
    r.Literal('rfq.unauthorized'),
    r.Literal('rfq.workflow_changes_only_allowed_by_ems'),
    r.Literal('self_signup_settings.contact_person_must_be_from_ems'),
    r.Literal('self_signup_settings.contact_person_not_found'),
    r.Literal('self_signup_settings.invalid_color_scheme'),
    r.Literal('self_signup_settings.invalid_organization_logo'),
    r.Literal('site.cannot_delete_default_inventory_site'),
    r.Literal('site.cannot_delete_inventory_site_with_linked_inventory_offers'),
    r.Literal('site.cannot_remove_default_setting_from_last_inventory_site'),
    r.Literal('site.invalid_default_site_setting'),
    r.Literal('site.must_have_at_least_one_tag'),
    r.Literal('site.name_already_exists'),
    r.Literal('site.site_number_already_exists'),
    r.Literal('solution_configuration.invalid_offers'),
    r.Literal('solution_configuration.invalid_scrap_quantity'),
    r.Literal('solution_configuration.no_design_items'),
    r.Literal('solution_configuration.no_part_options'),
    r.Literal('solution_configuration.solution_not_found'),
    r.Literal('sourcing_scenario.name_already_exists'),
    r.Literal('sourcing_scenario.not_found'),
    r.Literal('supplier_and_stock_location.already_exists'),
    r.Literal('supplier_contact.cannot_edit_invited_supplier_contact'),
    r.Literal('supplier.name.already_exists'),
    r.Literal('supplier.name.empty'),
    r.Literal('supplier.not_found'),
    r.Literal('supplier.supplier_number.already_exists'),
    r.Literal('supplier.supplier_number.empty'),
    r.Literal('unauthorized'),
    r.Literal('unknown'),
);

export type ErrorCode = r.Static<typeof ErrorCodeRuntype>;
