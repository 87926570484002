import { DB } from '@luminovo/http-client';
import { Query, QueryResult, Table } from '@synthql/queries';
import { SynthqlProvider, useSynthql } from '@synthql/react';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

export function SynthQLContextProvider({ children }: React.PropsWithChildren<{}>) {
    // ❗️❗️❗️ This is an experimental integration, only for testing purposes.
    // ❗️❗️❗️ We explicitly don't want to use this in production yet, so that's
    // ❗️❗️❗️ why you see the endpoint pointing to localhost.
    return (
        <SynthqlProvider
            value={{
                endpoint: 'http:///localhost:3001/api/gateway/synthql',
                requestInit: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            }}
        >
            {children}
        </SynthqlProvider>
    );
}

export function useSynth<TQuery extends Query<DB>>({
    query,
}: {
    query: TQuery;
}): UseQueryResult<QueryResult<DB, TQuery>> {
    return useSynthql<DB, Table<DB>, TQuery>(query, {
        reactQuery: {
            suspense: true,
        } as {},
    });
}
