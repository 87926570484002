import { t } from '@lingui/macro';
import { BaseButtonProps, DestructivePrimaryButton, PrimaryButton } from '@luminovo/design-system';
import { Tooltip } from '@mui/material';
import { useFormState } from 'react-hook-form';

type SubmitButtonProps = Omit<BaseButtonProps, 'type' | 'disabled' | 'href'> & {
    disabled?: boolean;
    isLoading?: boolean;
    submitButtonVariant?: 'normal' | 'destructive';
    label?: string | JSX.Element;
};

export function SubmitButton({
    disabled = false,
    isLoading,
    label = t`Save`,
    submitButtonVariant = 'normal',
    ...rest
}: SubmitButtonProps) {
    const { errors, isSubmitting } = useFormState();
    const hasErrors = Object.keys(errors).filter((key) => key !== 'root').length > 0;
    const loading = isLoading || isSubmitting;
    const buttonProps: BaseButtonProps = {
        disabled: isSubmitting || hasErrors || disabled,
        type: 'submit',
        style: { minWidth: 80 },
        ...rest,
    };

    return (
        <Tooltip placement="top" title={hasErrors ? t`Your form contains errors.` : ''}>
            <span>
                {submitButtonVariant === 'destructive' ? (
                    <DestructivePrimaryButton {...buttonProps}>{label}</DestructivePrimaryButton>
                ) : (
                    <PrimaryButton isLoading={loading} {...buttonProps}>
                        {label}
                    </PrimaryButton>
                )}
            </span>
        </Tooltip>
    );
}
