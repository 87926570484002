import { t } from '@lingui/macro';
import { get } from '@luminovo/commons';
import {
    colorSystem,
    FieldCheckboxControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    TertiaryIconButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { InfoRounded, Replay as ReplayIcon } from '@mui/icons-material';
import { InputAdornment, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { ColorDropDownFormItem, SolderMaskColors } from '../../../../components/formLayouts/ColorDropDown';
import { transEnum } from '../../../../components/localization/TransEnum';
import { capabilitiesTranslations, propertyDescriptionTranslations } from '../../../../resources/pcb/i18n';
import { Capability, REQUIRED_PCB_FIELDS } from '../../../../resources/pcb/pcbFunctions';
import { assertArray } from '../../../../utils/assertArray';
import { throwErrorUnlessProduction } from '../../../../utils/customConsole';
import { PcbFormToggleButton, PcbFormToggleButtonGroup } from '../PcbFormToggleButton';
import { InspectPdfButton } from '../PcbPdfExtraction/InspectPdfButton';
import { formatOptionLabel, getUnit } from './pcbFormItemUtils';

export function PcbFormItem({
    capability,
    disabled = false,
    style,
    extractedValue,
}: {
    capability: Capability;
    disabled?: boolean;
    style?: React.CSSProperties;
    extractedValue?: string | number;
}) {
    const { control, setValue, watch } = useFormContext<PCBV2['properties']>();
    const { restrictions, capabilityName, name, type, include } = capability;
    const isRequired = REQUIRED_PCB_FIELDS.includes(capabilityName);

    const actions = <InspectPdfButton capability={capability} />;

    if (!include) {
        return null;
    }

    const resetToExtractedValue = () => {
        setValue(`${name}`, extractedValue);
    };

    const value = watch(name)?.toString();

    // Color select field
    // eslint-disable-next-line spellcheck/spell-checker
    if (capabilityName === 'silkscreenColor' || capabilityName === 'soldermaskColor') {
        const options: SolderMaskColors[] = assertArray(restrictions.allowed);

        return (
            <ColorDropDownFormItem
                disabled={disabled}
                style={style}
                key={capabilityName}
                name={name}
                label={transEnum(capabilityName, capabilitiesTranslations)}
                description={transEnum(capabilityName, propertyDescriptionTranslations)}
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                color={value as SolderMaskColors}
                control={control}
                options={options}
                required={isRequired}
                actions={actions}
            />
        );
    }

    // Button group input field
    if (capabilityName === 'layerstackType') {
        // eslint-disable-next-line spellcheck/spell-checker
        const options = ['rigid', 'flex', 'rigid-flex', 'ims'];

        if (disabled) {
            return <Text variant="body">{value && formatOptionLabel(name, value)}</Text>;
        }

        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <PcbFormToggleButtonGroup
                            exclusive
                            fullWidth
                            size="small"
                            value={value}
                            defaultValue={value}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                        >
                            {options.map((value, index) => (
                                <PcbFormToggleButton key={index} value={value}>
                                    <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                                        {formatOptionLabel(name, value)}
                                    </Text>
                                </PcbFormToggleButton>
                            ))}
                        </PcbFormToggleButtonGroup>
                    );
                }}
            />
        );
    }

    // Checkbox input field
    if (type.tag === 'boolean') {
        const description = transEnum(String(capabilityName), propertyDescriptionTranslations);
        return (
            <Flexbox gap={'4px'} alignItems={'center'}>
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                        alignItems: 'center',
                    }}
                >
                    <FieldCheckboxControlled
                        control={control}
                        name={name}
                        FieldProps={{ disabled, size: 'small', style: { marginBlockEnd: '4px' } }}
                    />
                    <Text variant="h5">{transEnum(String(capabilityName), capabilitiesTranslations)}</Text>
                    {description && (
                        <Tooltip title={description} arrow>
                            <InfoRounded
                                style={{
                                    cursor: 'pointer',
                                    color: colorSystem.neutral[5],
                                    fontSize: '16px',
                                }}
                            />
                        </Tooltip>
                    )}
                </label>
                {actions && <span style={{ marginLeft: 'auto' }}>{actions}</span>}
            </Flexbox>
        );
    }

    // Select input field
    // NOTE: Since type.tag has the the boolean tag and it handle before this
    if (Array.isArray(restrictions.allowed)) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const options: (number | string)[] = restrictions.allowed as (number | string)[];

        return (
            <FormItem
                style={style}
                key={capabilityName}
                label={`${transEnum(String(capabilityName), capabilitiesTranslations)}${isRequired ? '*' : ''}`}
                LabelProps={{ variant: 'h5' }}
                description={transEnum(String(capabilityName), propertyDescriptionTranslations)}
                actions={actions}
            >
                {disabled ? (
                    <Flexbox style={{ paddingLeft: '5px' }} alignItems="center" gap={8}>
                        <Typography
                            variant="body2"
                            style={{ color: colorSystem.neutral[8], fontWeight: 400, fontSize: '14px' }}
                        >
                            {value && formatOptionLabel(name, value)}
                        </Typography>
                    </Flexbox>
                ) : (
                    <FieldSelectControlled
                        name={name}
                        control={control}
                        required={isRequired}
                        FieldProps={{
                            options,
                            disableClearable: true,
                            size: 'small',
                            getOptionLabel: (option) => formatOptionLabel(name, option),
                            renderOption: (option) => formatOptionLabel(name, option),
                        }}
                        validate={
                            name === 'board.placementSide'
                                ? (x) => {
                                      if (typeof x === 'string' && x === 'none') {
                                          return t`Required`;
                                      }
                                  }
                                : undefined
                        }
                    />
                )}
            </FormItem>
        );
    }

    // Number input field
    if (type.tag === 'number') {
        const min = 'min' in restrictions ? restrictions.min : undefined;
        const max = 'max' in restrictions ? restrictions.max : undefined;
        const unit = getUnit(name);

        if (get(restrictions, 'disabled', false)) {
            return null;
        }

        return (
            <FormItem
                style={style}
                key={capabilityName}
                label={`${transEnum(String(capabilityName), capabilitiesTranslations)}${isRequired ? '*' : ''}`}
                LabelProps={{ variant: 'h5' }}
                description={transEnum(String(capabilityName), propertyDescriptionTranslations)}
                actions={actions}
            >
                {disabled ? (
                    <Flexbox style={{ paddingLeft: '5px', minHeight: '20px' }} alignItems="center" gap={8}>
                        <Typography
                            variant="body2"
                            style={{
                                color: colorSystem.neutral[8],
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                        >
                            {value !== undefined ? `${value} ${unit ?? ''}` : '-'}
                        </Typography>
                    </Flexbox>
                ) : (
                    <Flexbox flexDirection={'row'} alignItems={'center'}>
                        <FieldNumericControlled
                            control={control}
                            required={isRequired}
                            name={name}
                            min={min}
                            max={max}
                            FieldProps={{
                                InputProps: {
                                    endAdornment: unit && <InputAdornment position="end">{unit}</InputAdornment>,
                                },
                                style: { width: '100%' },
                                size: 'small',
                            }}
                        />
                        {extractedValue !== undefined && String(extractedValue) !== value && (
                            <Tooltip title={t`Reset to extracted value`}>
                                <TertiaryIconButton onClick={resetToExtractedValue}>
                                    <ReplayIcon fontSize="inherit" />
                                </TertiaryIconButton>
                            </Tooltip>
                        )}
                    </Flexbox>
                )}
            </FormItem>
        );
    }

    throwErrorUnlessProduction(new Error('Unreachable code'));
    return null;
}
