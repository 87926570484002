import { colorSystem, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { RichTextEditor } from './RichTextEditor/RichTextEditor';
import { RichTextEditorProps } from './RichTextEditor/RichTextEditorTypes';

/**
 * A wrapper over the RichTextEditor component that looks like a regular input field.
 */
export function RichTextEditorInput(props: RichTextEditorProps): JSX.Element {
    const baseStyles = {
        position: 'relative',
        border: `1px solid ${colorSystem.neutral[3]}`,
        boxSizing: 'border-box',
        borderRadius: 1,
        padding: '6px 10px',
        paddingRight: 16,
        lineHeight: '2',
        '& p': {
            margin: 0,
        },

        '&:focus-within': {
            outline: `2px solid ${colorSystem.primary[3]}`,
            border: `1px solid ${colorSystem.primary[5]}`,
        },
        '&:hover': {
            border: `1px solid ${colorSystem.primary[5]}`,
        },
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
            <Box
                sx={
                    props.error
                        ? {
                              ...baseStyles,
                              border: `1px solid ${colorSystem.red[7]} !important`,
                          }
                        : baseStyles
                }
            >
                <RichTextEditor {...props} hideToolbar disableRichText />
            </Box>
            {props.error && <Text style={{ fontSize: 10, color: colorSystem.red[7] }}>{props.error}</Text>}
        </Box>
    );
}
