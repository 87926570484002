import { t } from '@lingui/macro';
import { PCBV2 } from '@luminovo/http-client';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
    createInitialPcbFormValues,
    PcbCapabilitiesType,
    usePcbCapabilities,
} from '../../../../resources/pcb/pcbFunctions';
import { useExtractedPcbCapabilities } from '../../components/PcbAnalysis/utils/usePcbPdfAnalysis/useExtractedPcbCapabilities';
import { useShowPcbPdfAnalysisMessageModal } from '../../components/PcbAnalysis/utils/useShowPcbPdfAnalysisMessageModal';

const mergePcbCapabilities = ({
    extractedPcbCapabilities,
    analyzedPcbCapabilities,
}: {
    extractedPcbCapabilities: PcbCapabilitiesType;
    analyzedPcbCapabilities: PcbCapabilitiesType;
}): PcbCapabilitiesType => {
    const mergedBasicCapabilities = analyzedPcbCapabilities.basic.map((analyzedCapability) => {
        // If the capability is present in extracted capabilities, use the extracted value
        // Otherwise, use the analyzed value
        const extractedCapability = extractedPcbCapabilities.basic.find(
            (extracted) => extracted.capabilityName === analyzedCapability.capabilityName,
        );
        if (extractedCapability) {
            return extractedCapability;
        } else {
            return analyzedCapability;
        }
    });

    const mergedAdvancedCapabilities = analyzedPcbCapabilities.advanced.map((analyzedCapability) => {
        // If the capability is present in extracted capabilities, use the extracted value
        // Otherwise, use the analyzed value
        const extractedCapability = extractedPcbCapabilities.advanced.find(
            (extracted) => extracted.capabilityName === analyzedCapability.capabilityName,
        );
        if (extractedCapability) {
            return extractedCapability;
        } else {
            return analyzedCapability;
        }
    });
    return {
        basic: mergedBasicCapabilities,
        advanced: mergedAdvancedCapabilities,
        fields: [...mergedBasicCapabilities, ...mergedAdvancedCapabilities],
    };
};

export const usePcbFormData = ({ pcb }: { pcb: PCBV2 }) => {
    const { userAgreesToExtraction } = useShowPcbPdfAnalysisMessageModal(pcb.id);
    // Analyzed by server
    const analyzedPcbCapabilities = usePcbCapabilities({ pcb });
    const analyzedValues = React.useMemo(
        () =>
            createInitialPcbFormValues({
                pcb,
                fields: analyzedPcbCapabilities.fields,
            }),
        [pcb, analyzedPcbCapabilities.fields],
    );

    // Extracted from specification
    const extractedPcbCapabilities = useExtractedPcbCapabilities({
        pcbId: pcb.id,
        pcbCapabilities: analyzedPcbCapabilities,
    });
    const mergedPcbCapabilities = React.useMemo(
        () =>
            mergePcbCapabilities({
                extractedPcbCapabilities: extractedPcbCapabilities,
                analyzedPcbCapabilities: analyzedPcbCapabilities,
            }),
        [extractedPcbCapabilities, analyzedPcbCapabilities],
    );

    const extractedValues = React.useMemo(
        () =>
            createInitialPcbFormValues({
                pcb,
                fields: mergedPcbCapabilities.fields,
            }),
        [pcb, mergedPcbCapabilities.fields],
    );

    const defaultValues = userAgreesToExtraction ? extractedValues : analyzedValues;
    const defaultPcbCapabilities = mergedPcbCapabilities;

    // Set the default values
    const useFormReturn = useForm({
        defaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const { reset, setError } = useFormReturn;

    React.useEffect(() => {
        const values = createInitialPcbFormValues({
            pcb,
            fields: userAgreesToExtraction ? mergedPcbCapabilities.fields : analyzedPcbCapabilities.fields,
        });

        if (Object.keys(values.board).length !== 0) reset(values);

        if (values.board.placementSide === 'none') {
            setError('board.placementSide', { type: 'required', message: t`Required` });
        }
    }, [
        pcb,
        defaultPcbCapabilities.fields,
        reset,
        setError,
        userAgreesToExtraction,
        mergedPcbCapabilities.fields,
        analyzedPcbCapabilities.fields,
    ]);

    return {
        useFormReturn,
        pcbCapabilities: defaultPcbCapabilities,
        defaultValues,
        extractedValues,
        extractedPcbCapabilities,
    };
};
